import React from 'react';
import './apidiff.scss';
import RawHTML from '../../../../../../components/RawHTML';
import Container from '../../../../../../components/Container';

const html = `<html>
<head>
<link rel="stylesheet" href="apidiff.css" type="text/css" />
<meta charset="utf-8" />
</head>
<body>

<div class="headerFile">
<div class="headerName">PDFNet/PDFNetOBJC.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> PTSTATUS_ERR</div>
<div class="difference"><span class="status removed">Removed</span> PTSTATUS_OK</div>
<div class="difference"><span class="status removed">Removed</span> PTSTATUS_NETWORK_ERR</div>
<div class="difference"><span class="status removed">Removed</span> PTSTATUS_BAD_CREDENTIALS</div>
<div class="difference"><span class="status removed">Removed</span> PTSTATUS_SERVICE_DOWN</div>
<div class="difference"><span class="status removed">Removed</span> PTSTATUS_INVALID_OPERATION</div>
<div class="difference"><span class="status removed">Removed</span> PTCloudErrorCode</div>
<div class="difference"><span class="status removed">Removed</span> PTSTATUS_NUM</div>
<div class="difference"><span class="status removed">Removed</span> PTVectorString</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString getCptr]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString initWithCptr:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString setSwigCMemOwn:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString dealloc]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString size]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString capacity]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString reserve:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString isEmpty]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString clear]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString add:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString get:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString set:val:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTVectorString init]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTConvert ToTiff:out_path:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTElementWriter WriterBeginWithPage:placement:page_coord_sys:compress:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTElementWriter Begin:placement:page_coord_sys:]</div>
<div class="difference"><span class="status removed">Removed</span> -[PTElementWriter WriterBeginWithSDFObj:compress:]</div>
<div class="difference"><span class="status removed">Removed</span> +[PTPDFNet ConnectToCloud:password:demo_mode:]</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> e_ptadbe_x509_rsa_sha1</div>
<div class="difference"><span class="status added">Added</span> e_ptadbe_pkcs7_detached</div>
<div class="difference"><span class="status added">Added</span> e_ptadbe_pkcs7_sha1</div>
<div class="difference"><span class="status added">Added</span> e_ptETSI_CAdES_detached</div>
<div class="difference"><span class="status added">Added</span> e_ptETSI_RFC3161</div>
<div class="difference"><span class="status added">Added</span> e_ptunknown</div>
<div class="difference"><span class="status added">Added</span> e_ptabsent</div>
<div class="difference"><span class="status added">Added</span> PTDigitalSignatureFieldSubFilterType</div>
<div class="difference"><span class="status added">Added</span> e_ptno_changes_allowed</div>
<div class="difference"><span class="status added">Added</span> e_ptformfilling_signing_allowed</div>
<div class="difference"><span class="status added">Added</span> e_ptannotating_formfilling_signing_allowed</div>
<div class="difference"><span class="status added">Added</span> e_ptunrestricted</div>
<div class="difference"><span class="status added">Added</span> PTDigitalSignatureFieldDocumentPermissions</div>
<div class="difference"><span class="status added">Added</span> e_ptdigsig_permission_lock_all</div>
<div class="difference"><span class="status added">Added</span> e_ptdigsig_permission_include</div>
<div class="difference"><span class="status added">Added</span> e_ptdigsig_permission_exclude</div>
<div class="difference"><span class="status added">Added</span> PTDigitalSignatureFieldFieldPermissions</div>
<div class="difference"><span class="status added">Added</span> PTSDFDocSnapshot</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFDocSnapshot getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFDocSnapshot initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFDocSnapshot setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFDocSnapshot dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFDocSnapshot Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFDocSnapshot GetHash]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFDocSnapshot IsValid]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFDocSnapshot Equals:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFDocSnapshot init]</div>
<div class="difference"><span class="status added">Added</span> PTSDFResultSnapshot</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot CurrentState]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot PreviousState]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot IsOk]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot IsNullTransition]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFResultSnapshot init]</div>
<div class="difference"><span class="status added">Added</span> PTSDFUndoManager</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager DiscardAllSnapshots]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager Undo]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager CanUndo]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager GetNextUndoSnapshot]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager Redo]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager CanRedo]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager GetNextRedoSnapshot]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager TakeSnapshot]</div>
<div class="difference"><span class="status added">Added</span> -[PTSDFUndoManager init]</div>
<div class="difference"><span class="status added">Added</span> -[PTField IsLockedByDigitalSignature]</div>
<div class="difference"><span class="status added">Added</span> PTDigitalSignatureField</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField HasCryptographicSignature]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetSubFilter]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetSignatureName]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetSigningTime]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetLocation]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetReason]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetContactInfo]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetCert:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetCertCount]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField HasVisibleAppearance]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField SetContactInfo:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField SetLocation:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField SetReason:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField SetFieldPermissions:in_field_names:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField SetDocumentPermissions:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField SignOnNextSave:in_password:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField SignOnNextSaveWithCustomHandler:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField CertifyOnNextSave:in_password:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField CertifyOnNextSaveWithCustomHandler:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetSDFObj]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField IsLockedByDigitalSignature]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetLockedFields]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField GetDocumentPermissions]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField ClearSignature]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureField initWithIn_field:]</div>
<div class="difference"><span class="status added">Added</span> PTDigitalSignatureFieldIterator</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureFieldIterator getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureFieldIterator initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureFieldIterator setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureFieldIterator dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureFieldIterator Destroy]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureFieldIterator Next]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureFieldIterator Current]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureFieldIterator HasNext]</div>
<div class="difference"><span class="status added">Added</span> -[PTDigitalSignatureFieldIterator init]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions GetAddGroupAnnots]</div>
<div class="difference"><span class="status added">Added</span> -[PTDiffOptions SetAddGroupAnnots:]</div>
<div class="difference"><span class="status added">Added</span> PTOCRModule</div>
<div class="difference"><span class="status added">Added</span> -[PTOCRModule getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCRModule initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCRModule setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCRModule dealloc]</div>
<div class="difference"><span class="status added">Added</span> +[PTOCRModule IsModuleAvailable]</div>
<div class="difference"><span class="status added">Added</span> +[PTOCRModule ImageToPDF:src:options:]</div>
<div class="difference"><span class="status added">Added</span> +[PTOCRModule ProcessPDF:options:]</div>
<div class="difference"><span class="status added">Added</span> +[PTOCRModule GetOCRJsonFromImage:src:options:]</div>
<div class="difference"><span class="status added">Added</span> +[PTOCRModule GetOCRJsonFromPDF:options:]</div>
<div class="difference"><span class="status added">Added</span> +[PTOCRModule ApplyOCRJsonToPDF:json:]</div>
<div class="difference"><span class="status added">Added</span> +[PTOCRModule GetOCRXmlFromImage:src:options:]</div>
<div class="difference"><span class="status added">Added</span> +[PTOCRModule GetOCRXmlFromPDF:options:]</div>
<div class="difference"><span class="status added">Added</span> +[PTOCRModule ApplyOCRXmlToPDF:xml:]</div>
<div class="difference"><span class="status added">Added</span> -[PTOCRModule init]</div>
<div class="difference"><span class="status added">Added</span> +[PTSound CreateWithData:pos:source_data:bits_per_sample:sample_freq:num_channels:icon:]</div>
<div class="difference"><span class="status added">Added</span> -[PTWidget GetTextColor]</div>
<div class="difference"><span class="status added">Added</span> -[PTWidget GetTextColorCompNum]</div>
<div class="difference"><span class="status added">Added</span> -[PTWidget SetTextColor:col_comp:]</div>
<div class="difference"><span class="status added">Added</span> -[PTWidget GetFontSize]</div>
<div class="difference"><span class="status added">Added</span> -[PTWidget SetFontSize:]</div>
<div class="difference"><span class="status added">Added</span> -[PTWidget GetFont]</div>
<div class="difference"><span class="status added">Added</span> -[PTWidget SetFont:]</div>
<div class="difference"><span class="status added">Added</span> PTCheckBoxWidget</div>
<div class="difference"><span class="status added">Added</span> -[PTCheckBoxWidget getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTCheckBoxWidget initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCheckBoxWidget setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCheckBoxWidget dealloc]</div>
<div class="difference"><span class="status added">Added</span> +[PTCheckBoxWidget Create:pos:field_name:]</div>
<div class="difference"><span class="status added">Added</span> +[PTCheckBoxWidget CreateWithField:pos:field:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCheckBoxWidget IsChecked]</div>
<div class="difference"><span class="status added">Added</span> -[PTCheckBoxWidget SetChecked:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCheckBoxWidget init]</div>
<div class="difference"><span class="status added">Added</span> -[PTCheckBoxWidget initWithAnnot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTCheckBoxWidget initWithD:]</div>
<div class="difference"><span class="status added">Added</span> PTComboBoxWidget</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget dealloc]</div>
<div class="difference"><span class="status added">Added</span> +[PTComboBoxWidget Create:pos:field_name:]</div>
<div class="difference"><span class="status added">Added</span> +[PTComboBoxWidget CreateWithField:pos:field:]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget AddOption:]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget AddOptions:]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget SetSelectedOption:]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget GetSelectedOption]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget init]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget initWithAnnot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTComboBoxWidget initWithD:]</div>
<div class="difference"><span class="status added">Added</span> PTListBoxWidget</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget dealloc]</div>
<div class="difference"><span class="status added">Added</span> +[PTListBoxWidget Create:pos:field_name:]</div>
<div class="difference"><span class="status added">Added</span> +[PTListBoxWidget CreateWithField:pos:field:]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget AddOption:]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget AddOptions:]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget SetSelectedOptions:]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget GetSelectedOptions]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget init]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget initWithAnnot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTListBoxWidget initWithD:]</div>
<div class="difference"><span class="status added">Added</span> PTPushButtonWidget</div>
<div class="difference"><span class="status added">Added</span> -[PTPushButtonWidget getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTPushButtonWidget initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPushButtonWidget setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPushButtonWidget dealloc]</div>
<div class="difference"><span class="status added">Added</span> +[PTPushButtonWidget Create:pos:field_name:]</div>
<div class="difference"><span class="status added">Added</span> +[PTPushButtonWidget CreateWithField:pos:field:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPushButtonWidget init]</div>
<div class="difference"><span class="status added">Added</span> -[PTPushButtonWidget initWithAnnot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPushButtonWidget initWithD:]</div>
<div class="difference"><span class="status added">Added</span> PTRadioButtonWidget</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonWidget getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonWidget initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonWidget setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonWidget dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonWidget GetGroup]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonWidget EnableButton]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonWidget IsEnabled]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonWidget init]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonWidget initWithAnnot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonWidget initWithD:]</div>
<div class="difference"><span class="status added">Added</span> PTRadioButtonGroup</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup dealloc]</div>
<div class="difference"><span class="status added">Added</span> +[PTRadioButtonGroup Create:field_name:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup Add:onstate:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup GetNumButtons]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup GetButton:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup GetField]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup AddGroupButtonsToPage:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup initWithField:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup initWithGroup:]</div>
<div class="difference"><span class="status added">Added</span> -[PTRadioButtonGroup initWithImpl:]</div>
<div class="difference"><span class="status added">Added</span> PTSignatureWidget</div>
<div class="difference"><span class="status added">Added</span> -[PTSignatureWidget getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignatureWidget initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignatureWidget setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignatureWidget dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignatureWidget GetDigitalSignatureField]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignatureWidget CreateSignatureAppearance:]</div>
<div class="difference"><span class="status added">Added</span> +[PTSignatureWidget Create:pos:field_name:]</div>
<div class="difference"><span class="status added">Added</span> +[PTSignatureWidget CreateWithField:pos:field:]</div>
<div class="difference"><span class="status added">Added</span> +[PTSignatureWidget CreateWithDigitalSignatureField:pos:field:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignatureWidget init]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignatureWidget initWithAnnot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSignatureWidget initWithD:]</div>
<div class="difference"><span class="status added">Added</span> PTTextWidget</div>
<div class="difference"><span class="status added">Added</span> -[PTTextWidget getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextWidget initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextWidget setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextWidget dealloc]</div>
<div class="difference"><span class="status added">Added</span> +[PTTextWidget Create:pos:field_name:]</div>
<div class="difference"><span class="status added">Added</span> +[PTTextWidget CreateWithField:pos:field:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextWidget SetText:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextWidget GetText]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextWidget init]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextWidget initWithAnnot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTTextWidget initWithD:]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert ToTiff:out_path:options:]</div>
<div class="difference"><span class="status added">Added</span> +[PTConvert FromCAD:in_filename:opts:]</div>
<div class="difference"><span class="status added">Added</span> -[PTElementWriter WriterBeginWithPage:placement:page_coord_sys:compress:resources:]</div>
<div class="difference"><span class="status added">Added</span> -[PTElementWriter Begin:placement:page_coord_sys:compress:]</div>
<div class="difference"><span class="status added">Added</span> -[PTElementWriter WriterBeginWithSDFObj:compress:resources:]</div>
<div class="difference"><span class="status added">Added</span> -[PTElementWriter SetDefaultGState:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc GetUndoManager]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc CreateDigitalSignatureField:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc GetDigitalSignatureFieldIterator]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFDoc GetDigitalSignaturePermissions]</div>
<div class="difference"><span class="status added">Added</span> PTSeparation</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation getCptr]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation initWithCptr:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation setSwigCMemOwn:]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation dealloc]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation C]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation M]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation Y]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation K]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation GetDataSize]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation GetSeparationName]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation GetData]</div>
<div class="difference"><span class="status added">Added</span> -[PTSeparation init]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFRasterizer RasterizeSeparations:width:height:mtx:clip:cancel:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFDraw GetSeparationBitmaps:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView SetSnappingMode:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView GetAnnotationsOnPage:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView EnableUndoRedo]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView Undo]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView Redo]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView TakeSnapshot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView GetNextUndoInfo]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView GetNextRedoInfo]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFView RevertAllChanges]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">PDFNet/PDFViewCtrl.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl addFloatingView:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl addFloatingView:toPage:withPageRect:noZoom:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl addFloatingView:toPage:atPagePoint:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl clearFloatingViews]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl removeFloatingView:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl removeFloatingViews:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl floatingViewsOnPage:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl clearFloatingViewPageCache]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl GetAnnotationsOnPage:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl EnableUndoRedo]</div>
<div class="difference"><span class="status added">Added</span> PTPDFViewCtrl.undoRedoEnabled</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl Undo]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl Redo]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl TakeUndoSnapshot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl GetNextUndoInfo]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl GetNextRedoInfo]</div>
<div class="difference"><span class="status added">Added</span> -[PTPDFViewCtrl RevertAllChanges]</div>
</div>

</div>



<div class="headerFile">
<div class="headerName">Tools/AnnotTypes.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTImageStampAnnotationRotationIdentifier</div>
<div class="difference"><span class="status added">Added</span> PTImageStampAnnotationRotationDegreeIdentifier</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationAnnotation.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> PTCollaborationAnnotation.annotationID</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) NSString *annotationID</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nullable) NSString *annotationID</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTCollaborationAnnotation.userID</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) NSString *userID</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nullable) NSString *userID</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTCollaborationAnnotation.userName</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) NSString *userName</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nullable) NSString *userName</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTCollaborationAnnotation.xfdf</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) NSString *xfdf</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nullable) NSString *xfdf</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTCollaborationAnnotation.parent</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) NSString *parent</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nullable) NSString *parent</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> PTCollaborationAnnotation.documentID</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">@property (nonatomic, copy, nonnull) NSString *documentID</td></tr>
<tr><th>To</th><td class="declaration">@property (nonatomic, copy, nullable) NSString *documentID</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTCollaborationManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTCollaborationManagerRemoteAnnotationAddedNotification</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationManagerRemoteAnnotationModifiedNotification</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationManagerRemoteAnnotationRemovedNotification</div>
<div class="difference"><span class="status added">Added</span> PTCollaborationManagerAnnotationUserInfoKey</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTSelectionRectContainerView.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTSelectionRectContainerView.groupSelectionRectView</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTToolManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTToolManager.annotationAuthorCheckEnabled</div>
<div class="difference"><span class="status added">Added</span> PTToolManager.undoRedoManager</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager annotationAdded:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager annotationModified:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager annotationRemoved:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager formFieldDataModified:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager pageAddedAtPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager pageMovedFromPageNumber:toPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManager pageRemovedForPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerDelegate toolManager:pageAddedForPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerDelegate toolManager:pageMovedFromPageNumber:toPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTToolManagerDelegate toolManager:pageRemovedForPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerPageAddedNotification</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerPageMovedNotification</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerPageRemovedNotification</div>
<div class="difference"><span class="status added">Added</span> PTToolManagerPreviousPageNumberUserInfoKey</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status modified">Modified</span> -[PTToolEvents tool:shouldShowMenu:forAnnotation:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)tool:(nonnull PTTool *)tool shouldShowMenu:(nonnull UIMenuController *)menuController forAnnotation:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)tool:(nonnull PTTool *)tool shouldShowMenu:(nonnull UIMenuController *)menuController forAnnotation:(nullable PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
<div class="difference"><span class="status modified">Modified</span> -[PTToolManagerDelegate toolManager:shouldShowMenu:forAnnotation:onPageNumber:]</div>
<table>
<tr><th></th><th>Declaration</th></tr>
<tr><th>From</th><td class="declaration">- (BOOL)toolManager:(nonnull PTToolManager *)toolManager shouldShowMenu:(nonnull UIMenuController *)menuController forAnnotation:(nonnull PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
<tr><th>To</th><td class="declaration">- (BOOL)toolManager:(nonnull PTToolManager *)toolManager shouldShowMenu:(nonnull UIMenuController *)menuController forAnnotation:(nullable PTAnnot *)annotation onPageNumber:(unsigned long)pageNumber</td></tr>
</table>
<br>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/PTUndoRedoManager.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> PTUndoRedoManager</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager initWithToolManager:]</div>
<div class="difference"><span class="status added">Added</span> PTUndoRedoManager.toolManager</div>
<div class="difference"><span class="status added">Added</span> PTUndoRedoManager.enabled</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager undo]</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager redo]</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager takeUndoSnapshot:]</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager annotationAdded:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager annotationModified:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager annotationRemoved:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager formFieldDataModified:onPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager pageAddedAtPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager pageMovedFromPageNumber:toPageNumber:]</div>
<div class="difference"><span class="status added">Added</span> -[PTUndoRedoManager pageRemovedForPageNumber:]</div>
</div>

</div>

<div class="headerFile">
<div class="headerName">Tools/ToolsMacros.h</div>

<div class="differenceGroup">
<div class="difference"><span class="status removed">Removed</span> #def PT_PROP_KEY_PATH</div>
</div>


<div class="differenceGroup">
<div class="difference"><span class="status added">Added</span> #def PT_KEY</div>
<div class="difference"><span class="status added">Added</span> #def PT_KEY_PATH</div>
<div class="difference"><span class="status added">Added</span> #def PT_CLASS_KEY</div>
<div class="difference"><span class="status added">Added</span> #def PT_CLASS_KEY_PATH</div>
<div class="difference"><span class="status added">Added</span> #def PT_PROTOCOL_KEY</div>
<div class="difference"><span class="status added">Added</span> #def PT_PROTOCOL_KEY_PATH</div>
</div>

</div>
</body>
</html>

`

export default (props) => {
  return (
    <Container className='api-dif'>
      <RawHTML html={html}/>
    </Container>
  )
}